.ag-theme-mycustomtheme {
  /* --ag-cell-horizontal-padding: 0px;
  --ag-cell-widget-spacing: 0px;
  --ag-grid-size: 5.2px; */
  /* --ag-list-item-height: 30px; */
  --ag-selected-row-background-color: #e9f0f8;
  --ag-row-hover-color: #dfe2e8;
  --ag-foreground-color: #555555;
  --ag-range-selection-border-style: none;
  /* 협업 css */
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

/* CELL */
/* 
.ag-theme-mycustomtheme .ag-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ag-theme-mycustomtheme .ag-cell-wrapper {
  flex-grow: 1;
} */
/* CELL HEADER */
/* .ag-theme-mycustomtheme .ag-header-cell-label {
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* CELL BODY */
/* .ag-theme-mycustomtheme .ag-cell {
  border-radius: 4px;
  text-align: center;
} */

/* 헤더 */
.ag-theme-mycustomtheme .ag-header-cell-text {
  /* 가운데 정렬 */
  margin: auto;
}
.ag-theme-mycustomtheme .ag-header-group-text {
  /* 가운데 정렬 */
  margin: auto;
}

/* CHECK-BOX */
.ag-theme-mycustomtheme .ag-checkbox {
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ag-theme-mycustomtheme .ag-selection-checkbox {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 협업 css */

/* .ag-theme-mycustomtheme .ag-header-cell:hover .ag-header-cell-resize {
  --ag-header-column-resize-handle-display: block;
} */

/* .ag-theme-mycustomtheme .ag-header-cell:hover .ag-header-cell-resize:active {
  --ag-header-column-resize-handle-color: rgb(var(--C-1f62b9));
} */

/* main(메인) */
.ag-red {
  background-color: #feb8b8;
}
.ag-green {
  background-color: #a0e6c8;
}
