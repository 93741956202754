.react-datepicker {
  border-radius: 4px;
  box-shadow: 0 3px 12px 0 #4b55624d;
  padding: 0.438rem 1rem 0.25rem;
  background-color: #fff;
  margin-top: 0.25rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 0.188rem;
  font-size: 0.875rem;
}

.react-datepicker__day-name {
  color: #999;
  font-weight: bold;
}

.react-datepicker__day {
  color: #555;
}

.react-datepicker__day--outside-month {
  color: #999;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 9999px;
  background-color: #f1f3f6;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 9999px;
  background-color: #1f62b9;
  color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1f62b9;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border-radius: 9999px;
  border: 1px solid #1f62b9;
}

.react-datepicker__input-time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top: solid 1px #dfe2e8;
  padding: 0.375rem 0;
}

.react-datepicker-time__caption,
.react-datepicker-time__input-container {
  flex-basis: auto;
  flex: 1 1 auto;
}

.react-datepicker-time__input {
  display: flex;
  justify-content: flex-end;
}

.react-datepicker-time__caption {
  font-size: 0.875rem;
  color: #555;
  line-height: 2.25rem;
}
