.App {
  text-align: center;
}

/* MODAL */
.modal-wrapper {
  width: 0;
  height: 0;
  position: fixed;
  top: calc(50% - 300px);
  left: calc(50% - 200px);
  transform: translate(-50%, -50%);
  z-index: 999;
}
