/*************************** default rc-tree *****************************/
.ucTenantTree {
  margin: 0;
  border: 1px solid transparent;
}
.ucTenantTree-focused:not(.ucTenantTree-active-focused) {
  border-color: cyan;
}
.ucTenantTree .ucTenantTree-treenode {
  margin: 0;
  padding: 0;
  line-height: 24px;
  white-space: nowrap;
  list-style: none;
  outline: 0;
  /* 추가 */
  display: flex;
  align-items: center;
}
.ucTenantTree .ucTenantTree-treenode:hover {
  background-color: #f1f3f6;
}
.ucTenantTree-treenode-selected {
  background-color: #e9f0f8;
}
/* .ucTenantTree-treenode.ucTenantTree-treenode-switcher-open {
  background-color: rgb(231, 228, 228);
} */
.ucTenantTree .ucTenantTree-treenode .draggable {
  color: #333;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
}

/* 드래그중인 요소의 배경색 */
.ucTenantTree .ucTenantTree-treenode.dragging {
  background: #ebedf1f1;
  opacity: 0.8;
}

.ucTenantTree .ucTenantTree-treenode.drop-container > .draggable::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 2px red;
  content: '';
}

/* 드랍될 부모 요소의 왼쪽 선 */
.ucTenantTree .ucTenantTree-treenode.drop-container ~ .ucTenantTree-treenode {
  border-left: 2px solid #1f62b9;
}
.ucTenantTree .ucTenantTree-treenode.drop-target {
  /* background-color: yellowgreen; */
  background-color: #e9f0f8;
}
.ucTenantTree .ucTenantTree-treenode.drop-target ~ .ucTenantTree-treenode {
  border-left: none;
}
.ucTenantTree .ucTenantTree-treenode.filter-node > .ucTenantTree-node-content-wrapper {
  /* color: #a60000 !important; */
  color: blue !important;
  font-weight: bold !important;
}
.ucTenantTree .ucTenantTree-treenode ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ucTenantTree .ucTenantTree-treenode .ucTenantTree-node-content-wrapper {
  /* 
  display:inline-block;
  height: 24px; 
  */
  position: relative;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  /* 추가 */
  display: flex;
  flex-grow: 1;
}
.ucTenantTree-switcher {
  /* 추가 */
  flex-shrink: 0;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-switcher,
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-checkbox,
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-iconEle {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 2px;
  line-height: 16px;
  vertical-align: -0.125em;
  background-color: transparent;
  background-repeat: no-repeat;
  background-attachment: scroll;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-switcher.ucTenantTree-icon__customize,
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-checkbox.ucTenantTree-icon__customize,
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-iconEle.ucTenantTree-icon__customize {
  background-image: none;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-icon_loading {
  margin-right: 2px;
  vertical-align: top;
  background: url('data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7')
    no-repeat scroll 0 0 transparent;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-switcher.ucTenantTree-switcher-noop {
  cursor: auto;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-switcher.ucTenantTree-switcher_open {
  background-position: -93px -56px;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-switcher.ucTenantTree-switcher_close {
  background-position: -75px -56px;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-checkbox {
  width: 13px;
  height: 13px;
  margin: 0 3px;
  background-position: 0 0;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-checkbox-checked {
  background-position: -14px 0;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-checkbox-indeterminate {
  background-position: -14px -28px;
}
.ucTenantTree .ucTenantTree-treenode span.ucTenantTree-checkbox-disabled {
  background-position: 0 -56px;
}
.ucTenantTree
  .ucTenantTree-treenode
  span.ucTenantTree-checkbox.ucTenantTree-checkbox-checked.ucTenantTree-checkbox-disabled {
  background-position: -14px -56px;
}
.ucTenantTree
  .ucTenantTree-treenode
  span.ucTenantTree-checkbox.ucTenantTree-checkbox-indeterminate.ucTenantTree-checkbox-disabled {
  position: relative;
  background: #ccc;
  border-radius: 3px;
}
.ucTenantTree
  .ucTenantTree-treenode
  span.ucTenantTree-checkbox.ucTenantTree-checkbox-indeterminate.ucTenantTree-checkbox-disabled::after {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 5px;
  height: 0;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: scale(1);
  content: ' ';
}
.ucTenantTree:not(.ucTenantTree-show-line) .ucTenantTree-treenode .ucTenantTree-switcher-noop {
  background: none;
}
.ucTenantTree.ucTenantTree-show-line .ucTenantTree-treenode:not(:last-child) > ul {
  background: url('data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7') 0 0 repeat-y;
}
.ucTenantTree.ucTenantTree-show-line .ucTenantTree-treenode:not(:last-child) > .ucTenantTree-switcher-noop {
  background-position: -56px -18px;
}
.ucTenantTree.ucTenantTree-show-line .ucTenantTree-treenode:last-child > .ucTenantTree-switcher-noop {
  background-position: -56px -36px;
}
.ucTenantTree-child-tree {
  display: none;
}
.ucTenantTree-child-tree-open {
  display: block;
}
.ucTenantTree-treenode-disabled > span:not(.ucTenantTree-switcher),
.ucTenantTree-treenode-disabled > a,
.ucTenantTree-treenode-disabled > a span {
  color: #767676;
  cursor: not-allowed;
}
.ucTenantTree-treenode-active {
  background: rgba(0, 0, 0, 0.1);
}
/* .ucTenantTree-node-selected {
  background-color: #ffe6b0;
  box-shadow: 0 0 0 1px #ffb951;
  opacity: 0.8;
} */
.ucTenantTree-icon__open {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px -16px;
}
.ucTenantTree-icon__close {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px 0;
}
.ucTenantTree-icon__docu {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px -32px;
}
.ucTenantTree-icon__customize {
  margin-right: 2px;
  vertical-align: top;
}
.ucTenantTree-title {
  /* 
  원본
  display: inline-block; 
  */
  display: flex;
  flex-grow: 1;
}
.ucTenantTree-indent {
  display: inline-block;
  height: 0;
  vertical-align: bottom;
  position: relative;
}
.ucTenantTree-indent.ucTenantTree-indent-unit::after {
  width: 5px;
  left: 50%;
  top: 0;
  height: 100%;
  background: blue;
  position: absolute;
  content: '';
}
.ucTenantTree-indent-unit {
  display: inline-block;
  width: 16px;
}
.ucTenantTree-draggable-icon {
  display: inline-flex;
  justify-content: center;
  width: 16px;
}

/* 추가 */
.ucTenantTree-treenode.dragging {
}

/* .ucTenantTree-iconHandler::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
  background: black;
  position: absolute;
  content: '';
}

.ucTenantTree-iconHandler::before {
  height: 1px;
  left: 50%;
  top: 50%;
  width: 100%;
  background: black;
  position: absolute;
  content: '';
} */

.ucTenantTree-title-dragging {
  opacity: 0.1;
}

.node-motion {
  transition: all 0.3s;
  overflow-y: hidden;
}

.ucTenantTree-list {
  height: 100%;
}

.ucTenantTree-list-holder {
  height: 100%;
}
