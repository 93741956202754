/* EnrolmentOfUsersInBatches.css */
.custom-grid .ag-header-container {
    background: #F2F2F2 !important; /* 헤더 배경색 */
}

.custom-grid .ag-header-cell-resize{
    visibility: hidden;
}

.ag-theme-alpine .ag-row, .ag-theme-alpine .ag-cell {
    overflow: visible !important;
}

.tooltip {
    z-index: 9999 !important;
}