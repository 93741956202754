@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #c8cace;
  border-radius: 5px;
}

:root {
  --C-1f62b9: 31 98 185;
  --C-599aee: 89 154 238;
  --C-32418e: 50 65 142;
  --C-ebedf1: 235 237 241;
  --C-b4b5b8: 180 181 184;
  --C-f1f3f6: 241 243 246;
  --C-555555: 85 85 85;
  --C-d9e5f9: 217 229 249;
  --C-b8d3fe: 184 211 254;
  --C-86878b: 134 135 139;
  --C-c8cace: 200 202 206;
  --C-9d9ea2: 157 158 162;
  --C-666666: 102 102 102;
  --C-282625: 40 38 37;
  --C-f6ce65: 246 206 101;
  --C-fa114f: 250 17 79;
  --C-dfe2e8: 223 226 232;
  --C-18c0fd: 24 192 253;
  --C-9bd00d: 155 208 13;
  --C-2a3674: 42 54 116;
  --C-1f62b9: 31 98 185;
  --C-636465: 99 100 101;
  --C-00bf60: 0 191 96;
  --C-434343: 67 67 67;
  --C-707070: 112 112 112;
  --C-d5d7dc: 213 215 220;
  --C-459d17: 69 157 23;
  --C-70159b: 112 21 155;
  --C-118ab5: 17 138 181;
  --C-604790: 96 71 144;
  --C-38b5de: 56 181 222;
  --C-fa164f: 250 22 79;
  --C-006a66: 17 138 181;
  --C-ff9500: 0 106 102;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jcac {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 모달  */
.drag-box {
  overflow: hidden;
}
.ReactModal__Overlay {
  background-color: none;
}

/* input auto complete */
/* input:disabled {
  background: red !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
/* 
input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
} */

input:disabled:-webkit-autofill,
input:disabled:-webkit-autofill:hover,
input:disabled:-webkit-autofill:focus,
input:disabled:-webkit-autofill:active {
  /* -webkit-transition: background-color 5000s;
  transition: background-color 5000s; */
  /* background-color: red !important; */
  -webkit-box-shadow: 0 0 0 30px #ebedf1 inset !important;
  -webkit-text-fill-color: #b4b5b8 !important;

  /* -webkit-background-clip: text; */
  /* color: white !important; */
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  -webkit-text-fill-color: #000;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
} */
