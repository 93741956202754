.ag-header-viewport {
  background-color: #fff;
}

.ag-theme-alpine {
  overflow-x: hidden;
}

.ag-root-wrapper {
  border: 1px solid #dfe2e8;
}

.ag-theme-alpine .ag-header {
  border-bottom: 1px solid #dfe2e8 !important; /* 헤더 아래 선 제거 */
}

.ag-theme-alpine .ag-row {
  border-bottom: 1px solid #dfe2e8 !important; /* 본문 행 아래 선 제거 */
}
