*:focus { 
    outline:none; 
}

.__floater__body div div div {
    margin-top:12px;
    margin-bottom:-12px;
    margin-left:20px;
    margin-right:20px;
}

.__floater__body > div:first-child > div:nth-child(2) {
    margin-top:122px;
    margin-left:20px;
    margin-right:0px;
}

button[data-test-id="button-primary"]:hover {
    background-color: #599aee !important;
}