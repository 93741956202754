.SplitPane.custom {
  position: static !important;
}

.Resizer {
  background: #f1f3f6;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 20px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
}

.Resizer.horizontal:hover,
.Resizer.horizontal.resizing {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 20px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover,
.Resizer.vertical.resizing {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.DragLayer {
  z-index: 1;
  pointer-events: none;
}

.DragLayer.resizing {
  pointer-events: auto;
}

.DragLayer.horizontal {
  cursor: row-resize;
}

.DragLayer.vertical {
  cursor: col-resize;
}
